const FALLBACK_TARGET = '/account'

export default defineNuxtRouteMiddleware(({ query }) => {
  if (import.meta.server) return // fallback if route is not ssr: false in nuxt.config routeRules
  const { isLoggedIn } = useAuth()

  if (isLoggedIn.value) {
    const target = decodeRedirectQuery(query) || FALLBACK_TARGET
    return navigateTo(target)
  }
})
